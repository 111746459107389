import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import logo from './logo.svg';
import left from './left-image.png';
import right from './right-image.png';
import './App.css';

function App() {
  const [backgroundImage, setBackgroundImage] = useState(`url(${right})`);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [waitlistInput, setWaitlistInput] = useState('');

  // Formspree hook
  const [state, handleSubmit] = useForm("mwpezkaw");

  // Preload images
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    preloadImage(left);
    preloadImage(right);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientX < window.innerWidth / 2) {
        setBackgroundImage(`url(${left})`);
      } else {
        setBackgroundImage(`url(${right})`);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const toggleContact = () => {
    setIsContactOpen(!isContactOpen);
  };

  const handleInputChange = (e) => {
    setWaitlistInput(e.target.value);
  };

  return (
    <div className="App" style={{ backgroundImage: backgroundImage }}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Based Brands" />
      </header>
      <div className={`contact-box ${isContactOpen ? 'open' : ''}`} onClick={toggleContact}>
        <div className="contact-header">
          <span>{isContactOpen ? 'Contact' : 'Contact'}</span>
        </div>
        {isContactOpen && (
          <div className="contact-content">
            <span><a className="App-link" href="mailto:hello@basedbrands.io">hello@basedbrands.io</a></span>
          </div>
        )}
      </div>

      {/* Waitlist Sign-Up Form */}
      <div className="waitlist-form">
        {state.succeeded ? (
          <p className="confirmation-message">Thanks for joining the waitlist!</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              id="contactInfo"
              type="text"
              name="contactInfo"
              value={waitlistInput}
              onChange={handleInputChange}
              placeholder="Telegram @user or email"
              className="waitlist-input"
            />
            <ValidationError 
              prefix="Contact Info" 
              field="contactInfo"
              errors={state.errors}
            />
            <button type="submit" disabled={state.submitting} className="waitlist-submit">
              Sign Up for Waitlist
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default App;